import React from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { Box } from "@/shared/components";
import { PageHeader } from "@/shared/components/PageHeader";
import { useStoreContext } from "@/shared/hooks/useStore";
import { ReceiveCryptoStep, ReceiveCryptoStore, } from "@/pages/ReceiveCryptoPage/stores/ReceiveCryptoStore";
import { AddFunds } from "@/shared/components/AddFunds";
import { AccountListStore } from "@/shared/stores/AccountListStore";
export var GetAddress = observer(function () {
    var _a;
    var receiveCrypto = useStoreContext(ReceiveCryptoStore);
    var accountList = useStoreContext(AccountListStore);
    var account = accountList.checking;
    var onBack = function () {
        runInAction(function () {
            receiveCrypto.step = ReceiveCryptoStep.ChooseCrypto;
        });
    };
    React.useEffect(function () {
        var _a, _b;
        if (account &&
            ((_a = receiveCrypto.currency) === null || _a === void 0 ? void 0 : _a.slug) &&
            ((_b = receiveCrypto.blockchain) === null || _b === void 0 ? void 0 : _b.slug)) {
            receiveCrypto.getAddress(account.account, receiveCrypto.currency.slug, receiveCrypto.blockchain.slug);
        }
    }, [
        account,
        receiveCrypto.currency,
        receiveCrypto.blockchain,
        receiveCrypto.address,
    ]);
    return (React.createElement(Box, null,
        React.createElement(PageHeader, { title: "Receive ".concat((_a = receiveCrypto.currency) === null || _a === void 0 ? void 0 : _a.shortName), onBack: onBack }),
        receiveCrypto.currency &&
            receiveCrypto.blockchain &&
            receiveCrypto.address && (React.createElement(AddFunds, { selectedCurrency: receiveCrypto.currency, selectedBlockchain: receiveCrypto.blockchain, address: receiveCrypto.address }))));
});
